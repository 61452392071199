import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../components/Layout'
import { RingBaseSelect } from '../components/RingBaseSelect'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'

export const Head = () => (
    <SEO
        title="オーダーメイドの結婚指輪は1万点以上のデザインをカスタマイズできるCAD-platinumで"
        description="カスタマイズ、オーダーメイドの結婚指輪なら業界最大数、10,000点を超えるデザインから選べるCAD-platinum。シンプルなストレートラインからウェーブのダイヤリングなど豊富なデザインにオリジナルのアレンジも追加可能です。"
        page="top"
    />
)

const IndexPage: React.FC = () => {
    return (
        <Layout>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <TitleWrapper>
                        <TitleTxt size="l">10,000通りのデザイン</TitleTxt>
                        <TitleTxt size="l">WEBで簡単カスタマイズ</TitleTxt>
                    </TitleWrapper>
                    <StaticImage src="../images/top/hjo.jpg" alt="" />
                    <CatchWrapper>
                        <CatchTxt size="xl">選べる3つの製作コース</CatchTxt>
                    </CatchWrapper>
                    <TitleImageWrapper>
                        <StaticImage src="../images/top/w1.jpg" alt="" />
                    </TitleImageWrapper>
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">
                            スマホで簡単カスタマイズ
                        </DescriptionTxt>
                        <UL>
                            <LI>1万通りのデザイン</LI>
                            <LI>世界に一つのオリジナルアレンジ</LI>
                            <LI>店舗または自宅で試着</LI>
                        </UL>
                        <CatchImageWrapper>
                            <StaticImage
                                src="../images/top/semi-1.jpg"
                                alt=""
                            />
                        </CatchImageWrapper>
                        <OrderLink to="/semiorder/">
                            <OrderLinkTxt>まずはWEBでカスタマイズ</OrderLinkTxt>
                            <ChevronRightIcon />
                        </OrderLink>
                    </DescriptionWrapper>
                    <TitleImageWrapper>
                        <StaticImage src="../images/top/w2.jpg" alt="" />
                    </TitleImageWrapper>
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">
                            “CADデザイナー”がご提案する
                        </DescriptionTxt>
                        <DescriptionTxt size="l">
                            フルオーダーメイド
                        </DescriptionTxt>
                        <p>
                            経験豊富なCADデザイン専任スタッフがおふたりのご希望、イメージを反映した完成画像を数パターン無料で製作致します。
                        </p>
                        <CatchImageWrapper>
                            <StaticImage
                                src="../images/top/full-2.jpg"
                                alt=""
                            />
                        </CatchImageWrapper>
                        <DescriptionTxt>
                            「WEBでお試し」または「店舗でご相談」
                        </DescriptionTxt>
                        <DescriptionTxt>
                            最短即日、最終見積もりも確認できる
                        </DescriptionTxt>
                        <DescriptionTxt>デザイン画製作 0円</DescriptionTxt>
                        <OrderLink to="/designerorder/">
                            <OrderLinkTxt>
                                “CADデザイナー”によるご提案
                            </OrderLinkTxt>
                            <ChevronRightIcon />
                        </OrderLink>
                    </DescriptionWrapper>
                    <TitleImageWrapper>
                        <StaticImage src="../images/top/w3.jpg" alt="" />
                    </TitleImageWrapper>
                    <DescriptionWrapper>
                        <DescriptionTxt size="l">
                            “おふたり”がデザイナーの
                        </DescriptionTxt>
                        <DescriptionTxt size="l">
                            フルオーダーメイド
                        </DescriptionTxt>
                        <UL>
                            <LI>二人でデザインする特別感</LI>
                            <LI>
                                簡単な手書きデザイン画を送るだけで
                                <br />
                                きれいな造型データが完成
                            </LI>
                        </UL>
                        <CatchImageWrapper>
                            <StaticImage
                                src="../images/top/full-1.jpg"
                                alt=""
                            />
                        </CatchImageWrapper>
                        <DescriptionTxt>
                            リアルなCAD画像だから早くてわかりやすい
                        </DescriptionTxt>
                        <DescriptionTxt>「WEBでお試し」</DescriptionTxt>
                        <DescriptionTxt>
                            簡単デザイン画製作&amp;お見積り 0円
                        </DescriptionTxt>
                        <OrderLink to="/fullorder/">
                            <OrderLinkTxt size="l">
                                “おふたり”がデザイナー
                            </OrderLinkTxt>
                            <ChevronRightIcon />
                        </OrderLink>
                    </DescriptionWrapper>
                    <Divider />
                    <TitleImageWrapper>
                        <StaticImage src="../images/top/top2.jpg" alt="" />
                    </TitleImageWrapper>
                    <TitleImageWrapper>
                        <StaticImage src="../images/top/top3.jpg" alt="" />
                    </TitleImageWrapper>
                    <DescriptionWrapper>
                        <DescriptionTxt>スマホで簡単</DescriptionTxt>
                        <DescriptionTxt>
                            大まかな手書きのデザインを
                        </DescriptionTxt>
                        <DescriptionTxt>メールで送るだけで</DescriptionTxt>
                    </DescriptionWrapper>
                    <StaticImage
                        src="../images/fullorder/catalog/design-4.jpg"
                        alt=""
                    />
                    <DescriptionWrapper>
                        <DescriptionTxt>
                            最短即日で設計データ製作
                        </DescriptionTxt>
                        <DescriptionTxt>
                            写真化したリアルなCAD画像だから
                        </DescriptionTxt>
                        <DescriptionTxt>仕上がりがわかりやすい</DescriptionTxt>
                        <DescriptionTxt>ご来店、ご注文前でもOK</DescriptionTxt>
                    </DescriptionWrapper>
                    <OrderLink to="/fullorder/catalog/">
                        <StaticImage
                            src="../images/top/top-tk.jpg"
                            alt="オーダーメイドの結婚指輪カタログ"
                        />
                    </OrderLink>
                    <StaticImage src="../images/top/top5.jpg" alt="" />
                    <RingBaseSelect marginTop="20px" />
                </Grid>
            </Grid>
        </Layout>
    )
}

const TitleWrapper = styled('h1')({
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '40px',
})
const TitleTxt = styled(Txt)({
    marginBottom: '8px',
    fontWeight: 'normal',
})
const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '8px 8px 20px',
})
const CatchTxt = styled(Txt)({
    marginTop: '24px',
    marginBottom: '8px',
})
const DescriptionWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px 8px',
})
const DescriptionTxt = styled(Txt)({
    marginBottom: '16px',
})
const CatchImageWrapper = styled('div')({
    margin: '0 auto 8px',
    width: '50%',
})
const UL = styled('ul')({
    paddingRight: '40px',
})
const LI = styled('li')({
    fontSize: '16px',
    marginBottom: '8px',
})
const OrderLink = styled(Link)({
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    margin: '20px 0',
})
const OrderLinkTxt = styled(Txt)({
    marginRight: '20px',
})
const TitleImageWrapper = styled('div')({
    margin: '10px 0',
})
const Divider = styled('hr')({
    margin: '28px 0',
    borderStyle: 'none',
    borderTop: '1px solid #ccc',
})

export default IndexPage
